import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import './assets/global.css'
import { initPosthog } from './Posthog/Posthog'

Vue.config.productionTip = false
initPosthog()
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
