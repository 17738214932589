import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    
    theme: {
        themes: {
          light: {
            // Our default 'Gofer brown'
            primary: '#6E471C',
            lightPrimary: '#F0D8A8',
            darkPrimary: '#401D09'
          }
        }
    }        
});
