import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
Vue.use(VueRouter)
const routes: Array<RouteConfig> = [
    {
      path: '/',
      name: 'Landing Page',
      component: () => import('../views/LandingPage.vue')
    },
    {
      path: '/welcome-page',
      name: 'Welcome Page',
      component: () => import('../views/PostSignUp.vue')
    },
    {
      path: '/cookies',
      name: 'Cookies Page',
      component: () => import('../views/CookiesPage.vue')
    },
    {
      path: '/about-us',
      name: 'About Us Page',
      component: () => import('../views/AboutUsPage.vue')
    },
    {
      path: '/privacy-policy',
      name: 'Privacy Policy Page',
      component: () => import('../views/PrivacyPolicyPage.vue')
    },
    {
      path: '*',
      name: '404 Page',
      component: () => import('../views/404Page.vue')
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
